
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { TipoProcessoJudicial } from '@/core/models/cadastros';
import { TipoProcessoJudicialService } from '@/core/services/cadastros';

@Component
export default class ListaTipoProcessoJudicial extends mixins(Vue,ListPage) {

  item = new TipoProcessoJudicial();
  service = new TipoProcessoJudicialService();

  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;

  titulo: string = 'Tipo de Processo';
  subTitulo: string = 'Lista dos tipos de processos para o uso no Sistema';
  
  options: any = {
    itemsPerPage: 15
  };

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Nome', value: 'nome' },
  ];

  
   breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Tipos de Processo', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  @Watch("options", { deep: true })
  Atualizar() {
    const { page, itemsPerPage, sortBy, sortDesc } = this.options;
    this.loading = true;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers).then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => (this.loading = false));
  }

  Novo(){
    this.item = new TipoProcessoJudicial();
    this.dialogCadastro = true;
  }
  mounted(){
   // this.Atualizar();
  }
 
}

